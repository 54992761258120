<template>
  <v-list-item link :title="$t('menus.home')" :to="{ name: 'Home' }" color="white">
    <v-list-item-icon class="d-flex justify-center">
      <v-icon color="white">mdi-home</v-icon>
    </v-list-item-icon>
    <v-list-item-content link>
      <v-list-item-title class="white--text">
        {{ $t("menus.home") }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "MenuHome"
}
</script>

<style scoped>

</style>